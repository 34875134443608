import axios from 'axios'
import md5 from "js-md5";
import generateRandomNumber from '../utils/random.js'
// import router from "@/router";
export function request(config) {
	// 创建axios的实例
	const instance = axios.create({
		// 		baseURL: 'http://data-api.youxiang-ai.com',
		baseURL: 'https://data01.youxiang-ai.com',
		timeout: 30000
	})
	const Random = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
	const TimeStamp = parseInt(new Date().getTime() / 1000) + ""
	const Key = 'xingchen888'
	// 请求拦截器配置 window.sessionStorage.getItem('token')
	instance.interceptors.request.use(config => {
		config.headers.random = Random,
			config.headers.timestamp = TimeStamp,
			config.headers.key = Key,
			config.headers.sign = md5(Random + TimeStamp + Key)
		return config
	}, error => {
		console.log(error)
		return Promise.error(error)
	})
	// 响应拦截器配置
	instance.interceptors.response.use(response => {
		// console.log(response)
		return response.data
	}, error => {
		// switch (error.response.status) {
		// 	case 401:
		// 		console.log("无权访问")
		// 		// router.push({
		// 		// 	path: '/login'
		// 		// })
		// 		break
		// 	case 403:
		// 		console.log("token过期啦")
		// 		// router.push({
		// 		// 	path: '/login'
		// 		// })
		// 		break
		// 	case 404:
		// 		console.log("404啦")
		// 		// router.push({
		// 		// 	path: '/404'
		// 		// })
		// 		break
		// 	case 302:
		// 		console.log("秘钥错误")
		// 		// router.push({
		// 		// 	path: '/404'
		// 		// })
		// 		break
		// 	default:
		// 		return Promise.reject(error)
		// }
		return Promise.reject(error)
	})
	// 发送真正的网络请求
	return instance(config);
}
export default request