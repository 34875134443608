<template>
	<div>
		<div class="overlay" v-show="isLoding">
			<div style="z-index: 100000000; " class="loader">
				幼象正在努力加载中...</div>
		</div>
		<div id="particles-js" class="body">
			<div class="head">
				<HomeHead />
			</div>
			<div class="left">
				<HomeLeft />
			</div>
			<div class="right">
				<HomeRight />
			</div>
			<div class="map">
				<HomeMap />
			</div>
		</div>
	</div>
</template>

<script id="rendered-js">
	import {
		getSummary,
		md5test,
		getProvincesummary
	} from '@/api/home'
	import './../utils/particles.js'
	import HomeHead from '@/components/HomeComponents/HomeHead.vue'
	import HomeLeft from '@/components/HomeComponents/HomeLeft.vue'
	import HomeMap from '@/components/HomeComponents/HomeMap.vue'
	import HomeRight from '@/components/HomeComponents/HomeRight.vue'
	export default {
		name: 'HomeView',
		components: {
			HomeHead,
			HomeLeft,
			HomeMap,
			HomeRight
		},
		data() {
			return {

			}
		},
		computed: {
			isLoding() {
				return this.$store.state.isLoding
			}
		},
		methods: {
			async md5test() {
				const res = await md5test()
			}
		},
		mounted() {
			// this.md5test()
			// 调用vuex加载默认数据
			this.$store.dispatch('getInitializeData', '')
			// 背景动画JS代码
			particlesJS("particles-js", {
				"particles": {
					"number": {
						"value": 80,
						"density": {
							"enable": true,
							"value_area": 700
						}
					},


					"color": {
						"value": "#ffffff"
					},

					"shape": {
						"type": "circle",
						"stroke": {
							"width": 0,
							"color": "#000000"
						},

						"polygon": {
							"nb_sides": 5
						}
					},


					"opacity": {
						"value": 0.5,
						"random": false,
						"anim": {
							"enable": false,
							"speed": 0.1,
							"opacity_min": 0.1,
							"sync": false
						}
					},


					"size": {
						"value": 3,
						"random": true,
						"anim": {
							"enable": false,
							"speed": 10,
							"size_min": 0.1,
							"sync": false
						}
					},


					"line_linked": {
						"enable": true,
						"distance": 150,
						"color": "#ffffff",
						"opacity": 0.4,
						"width": 1
					},

					"move": {
						"enable": true,
						"speed": 2,
						"direction": "none",
						"random": false,
						"straight": false,
						"out_mode": "out",
						"bounce": false,
						"attract": {
							"enable": false,
							"rotateX": 600,
							"rotateY": 1200
						}
					}
				},



				"interactivity": {
					"detect_on": "canvas",
					"events": {
						"onhover": {
							"enable": true,
							"mode": "grab"
						},

						"onclick": {
							"enable": true,
							"mode": "push"
						},

						"resize": true
					},

					"modes": {
						"grab": {
							"distance": 140,
							"line_linked": {
								"opacity": 1
							}
						},


						"bubble": {
							"distance": 400,
							"size": 40,
							"duration": 2,
							"opacity": 8,
							"speed": 3
						},

						"repulse": {
							"distance": 200,
							"duration": 0.4
						},

						"push": {
							"particles_nb": 4
						},

						"remove": {
							"particles_nb": 2
						}
					}
				},



				"retina_detect": true
			});
		}

	}
</script>
<style scoped type="text/css">
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(5, 3, 10, 0.1);
		z-index: 9999;
		/* 设置遮罩层的层级，确保它在最上层 */
	}

	.body {
		height: 100vh;
		width: 100vw;
		background-size: 100% 100%;
		/* background-color: #000000; */
		background-image: url('../../src/assets/home/1.png');
	}

	.head {
		height: 12vh;
		width: 100vw;
		position: fixed;
		top: 0;
	}

	/* .left .right .map {
		height: 90vh;
	} */

	.left {
		height: 68vh;
		width: 30vw;
		/* border: 1px solid #ff0000; */
		position: fixed;
		left: 0;
		top: 10vh;
	}

	.right {
		height: 68vh;
		width: 30vw;
		/* width: 20vw; */
		/* border: 1px solid #ff0000; */
		position: fixed;
		right: 0;
		top: 10vh;
	}

	.map {
		width: 42vw;
		height: 85vh;
		/* border: 1px solid #ff0000; */
		position: fixed;
		right: 0;
		top: 10vh;
		left: 28vw;
	}

	canvas {
		display: block;
		vertical-align: bottom;
	}

	#particles-js {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #00356B;
	}

	.text {
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		color: #fff;
		max-width: 90%;
		padding: 2em 3em;
		background: rgba(0, 0, 0, 0.4);
		text-shadow: 0px 0px 2px #131415;
		font-family: "Open Sans", sans-serif;
	}

	h1 {
		font-size: 2.25em;
		font-weight: 700;
		letter-spacing: -1px;
	}

	a,
	a:visited {
		color: #e34234;
		transition: 0.25s;
	}

	a:hover,
	a:focus {
		color: #ba160c;
	}



	body {
		background: #ff3300;
	}

	.loader {
		width: 260px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		position: absolute;
		top: 95%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: helvetica, arial, sans-serif;
		text-transform: uppercase;
		font-weight: 900;
		color: #00cbff;
		letter-spacing: 0.2em;

		&::before,
		&::after {
			content: "";
			display: block;
			width: 15px;
			height: 15px;
			background: #00cbff;
			position: absolute;
			animation: load .7s infinite alternate ease-in-out;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}
	}

	@keyframes load {
		0% {
			left: 0;
			height: 30px;
			width: 15px
		}

		50% {
			height: 8px;
			width: 40px
		}

		100% {
			left: 235px;
			height: 30px;
			width: 15px
		}
	}
</style>