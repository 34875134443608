import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
// import china from 'echarts/map/json/china.json' //导入china包
// echarts.registerMap('china', china)



const app = createApp(App)
app.use(store).use(router)
app.config.globalProperties.$echarts = echarts
app.mount('#app')