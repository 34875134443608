<template>
	<div class="right-body">
		<div class="right-body-arc">
			<img style="height: 26vh;width: 2.1vw;margin-right: -2vw;" src="../../assets/home/right_arc_2.svg" alt=""
				srcset="" />
			<img style="height: 42h;width: 4vw;" src="../../assets/home/right_arc_1.svg" alt="" srcset="" />
		</div>
		<div style="width: 22.5vw;">
			<div class="right-body-number">
				{{rightTopText}}
			</div>
			<div class="fc" style="margin-top: 4vh;padding: 1vh 0vw 1vh 0.4vw;width: 100%;overflow-y: auto;">
				<ul>
					<div v-if="honeycombData.length==0" class="noata">
						幼象暂时找不到更多数据了
					</div>
					<li :key="item.name+item.value+index" v-bind:class="{ 'li-display': item.value==0 }"
						v-for="(item,index) in honeycombData.sort(function(a, b) {return b.value - a.value;})">
						<div class="hex">
							<div class="hexIn" style="" v-if="mapType == 1">
								<span>{{item.name}}</span>
								<span>{{item.value}}</span>
							</div>
							<div class="hexIn" style="" v-if="mapType == 0 && mapPorvinceType == 1">
								<span>{{item.name}}</span>
								<span>{{item.value}}/{{item.allCount}}</span>
							</div>
							<div class="hexIn" style="" v-if="mapType == 0 && mapPorvinceType == 0">
								<span>{{item.name}}</span>
							</div>
						</div>
					</li>

				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeRight',
		props: {

		},
		computed: {
			// 需要渲染的数据
			honeycombData() {
				return this.$store.state.honeycombData
			},
			// 右上角文字内容
			rightTopText() {
				return this.$store.state.rightTopText
			},
			mapPorvinceType() {
				return this.$store.state.mapPorvinceType
			},
			mapType() {
				return this.$store.state.mapType
			}
		},
		data() {
			return {

			}
		},
		created() {
			// var a=[]
		},
		methods: {

		}
	}
</script>


<style type="text/css" scoped>
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
		/* border-radius: 3px; */
	}

	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}

	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.7s;
	}

	/* 2. 声明进入和离开的状态 */
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0.1;
		transform: scaleY(0.01) translate(0, 0);
	}

	/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
	.fade-leave-active {
		position: absolute;
	}

	.noata {
		font-size: 0.8vw;
		animation: noataanimation 0.5s ease forwards;
	}

	@keyframes noataanimation {
		from {
			opacity: 0;
			transform: translateY(-20px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.right-body {
		height: 100%;
		padding-right: 1vw;
		padding-top: 2vh;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* width: 30vw; */
	}

	.right-body-number {
		/* width: 22vw; */
		background-image: url('../../assets/home/right_top.svg');
		background-size: 100% 100%;
		text-align: right;
		color: #6cf;
		padding: 0.4vh 0.7vw;
		font-size: 0.7vw;
	}

	.right-body-arc {
		display: flex;
		align-items: center;
		padding-top: 23vh;
		margin-right: -3vw;
	}


	.fc {
		height: 100%;
		background-color: rgb(3, 34, 67);
		border: 2px solid #ddd;
		border-image: linear-gradient(rgb(51, 153, 255), rgb(160, 218, 250)) 20 20;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	ul {
		color: #6cf;
		list-style: none;
		/* width: 22vw; */
		/* margin: 100px auto; */
	}

	.li-display {
		background-image: url('../../assets/home/honeycomb_no.png');
		background-size: 100% 100%;
		float: left;
		margin: 0 0.21vw;
		height: 8.4vh;
	}

	li {
		animation: fadeIn 0.5s ease forwards;
		background-image: url('../../assets/home/honeycomb1.png');
		background-size: 100% 100%;
		float: left;
		margin: 0 0.21vw;
		height: 8.4vh;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(-20px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	li:hover {
		transform: scale(1.1);
		/* 图片鼠标悬浮时放大 20% */
	}

	.hex {
		overflow: hidden;
		display: block;
		width: 3.9vw;
		height: 8.4vh;
		transform: rotate(-60deg) skewY(30.5deg);

	}

	.hexIn {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 0.6vw;
		width: 3.9vw;
		height: 8.4vh;
		text-align: center;
		transform: skewY(-30deg) rotate(60deg);
	}

	li:nth-child(9n + 6) {
		margin-left: 2.35vw;
		/* transition: margin-left 1s ease-in-out; */
		/* 添加移动动画 */
	}
</style>