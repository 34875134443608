import http from './http'
// 获取区县和智习室汇总
export function getSummary() {
	return http.get('/api/datacenter/getsummary')
}
// 省份智习室汇总 （各省份智习室数量）
export function getProvincesummary() {
	return http.get('/api/datacenter/getprovincesummary')
}
// 区县智习室数量 
export function getCountySummary(params) {
	return http.get('/api/datacenter/getcountysummary', params)
}
// 区县自习室数量
export function getCitysummary(params) {
	return http.get('/api/datacenter/getcitysummary', params)
}
// 各省代理数量
export function getProvinceList() {
	return http.get('/api/datacenter/getprovincelist')
}
// 各市代理数量
export function getCitylist(params) {
	return http.get('/api/datacenter/getcitylist', params)
}
// 区县代理数
export function getCountyList(params) {
	return http.get('/api/datacenter/getcountylist', params)
}
// 测试md5加密 /api/datacenter/md5
export function md5test(params) {
	return http.get('/api/datacenter/md5', params)
}